declare global {
  interface Window {
    OBR: {
      extern: {
        researchWidget: () => void;
      };
    };
    outbrain_enable?: number;
  }
}

import { loadScript } from "@sphtech/dmg-design-system";
import React, { memo, useEffect, useRef } from "react";

const OutbrainScript: React.FC<{ url: string }> = ({ url }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    void Promise.all([
      new Promise((resolve) => {
        if (window.outbrain_enable !== undefined) {
          resolve(window.outbrain_enable);
          return;
        }
        const onMessage = (event: MessageEvent) => {
          if (event.origin !== window.origin) return;
          if (event.data === "outbrain_enable") {
            resolve(window.outbrain_enable);
            window.removeEventListener("message", onMessage);
          }
        };
        window.addEventListener("message", onMessage);
      }),
      loadScript("https://widgets.outbrain.com/outbrain.js", true),
    ]).then(([enabled]) => {
      const container = ref.current;
      if (
        !enabled ||
        !container ||
        // only add once, e.g. in react strict mode
        container.childNodes.length !== 0
      ) {
        return;
      }
      const outbrainDiv = document.createElement("div");
      outbrainDiv.className = "OUTBRAIN";
      outbrainDiv.setAttribute("data-src", url);
      outbrainDiv.setAttribute(
        "data-widget-id",
        container.querySelectorAll(".OUTBRAIN").length === 0 ? "AR_1" : "AR_2",
      );
      container.appendChild(outbrainDiv);
      window.OBR.extern.researchWidget();
    });
  }, [url]);

  return <div ref={ref} />;
};

export default memo(OutbrainScript);
