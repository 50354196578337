import cx from "classnames";
import ResponsiveImage from "../../Element/ResponsiveImage";
import { VideoSummarySmall } from "../../Element/Typography";
import NaturalCarousel, {
  ArrowVariant,
} from "../../Utility/NaturalCarousel/NaturalCarousel";
import { VideoGalleryLandscapeProps } from "./VideoGalleryLandscape";
import styles from "./videogallerylandscape.module.css";

const Strip = ({
  items,
  gotoPosition,
  setGotoPosition,
  arrowSize,
  arrowVariant,
}: {
  items: VideoGalleryLandscapeProps["items"];
  gotoPosition: number;
  setGotoPosition: (position: number) => void;
  dividerVariant?: "primary" | "secondary";
  arrowSize?: number;
  arrowVariant?: ArrowVariant;
}) => {
  return (
    <div className={styles.stripContainer}>
      <div className={styles.stripCarouselContainer}>
        <NaturalCarousel
          isLooping={false}
          arrowSize={arrowSize}
          arrowVariant={arrowVariant}
        >
          {items?.map((item, index) => (
            <div
              key={index}
              onClick={() => setGotoPosition(index)}
              className={cx(
                styles.stripCarouselItem,
                gotoPosition === index && styles.active,
              )}
            >
              <div
                className={cx(
                  styles.stripCarouselItemImage,
                  gotoPosition === index && styles.active,
                )}
              >
                <ResponsiveImage
                  key={index}
                  src={item.src || ""}
                  alt={item.alt || ""}
                  srcWidth={item.srcWidth || 0}
                  srcHeight={item.srcHeight || 0}
                  displayWidth={135}
                  loading="lazy"
                />
              </div>

              <div className={styles.lineClamp}>
                <VideoSummarySmall>{item.caption}</VideoSummarySmall>
              </div>
            </div>
          ))}
        </NaturalCarousel>
      </div>
    </div>
  );
};

export default Strip;
