import React from "react";
import ResponsiveImage from "../../Element/ResponsiveImage";
import { ImageCrop } from "../../Utility/dataTypes";
import style from "./index.module.css";

export type RelatedArticleData = {
  title: string;
  url: string;
  image?: ImageCrop;
};

export type RelatedArticleProps = {
  /**
   * Related stories to display. Expected values are array of objects
   * such as [{id: 1, title: "I am title", url: "https://www.mywebsite.com", imgUrl: "https://placehold.co/600x400"},
   * {id: 2, title: "I am title 2", url: "https://www.mywebsite.com", imgUrl: "https://placehold.co/600x400"}
   * ]
   */
  stories: RelatedArticleData[];
  /**
   * This is an option to show or hide the image thumbnail for the entire component.
   * Allowing only to show the text. It superseded the logic of whether the related story
   * has a featured image or no. Her World as of the moment is requesting this option to entirely
   * hide the image leaving only the text to appear.
   */
  displayImage: boolean;
  imageErrorSrc?: string;
  headerText?: string;
  hasRelatedPrefix?: boolean;
};

const RelatedArticleItem = (props: {
  item: RelatedArticleData;
  displayImage: RelatedArticleProps["displayImage"];
  imageErrorSrc?: string;
  hasRelatedPrefix?: boolean;
}) => {
  //data extraction
  const { item, displayImage, hasRelatedPrefix = true } = props;
  const { url, title, image } = item;

  const imageContainer = displayImage && image && (
    <span className={style.imageContainer}>
      <ResponsiveImage
        {...image}
        displayWidth={225}
        errorSrc={props.imageErrorSrc}
      />
    </span>
  );

  return (
    <a href={url} className={style.itemContainer}>
      {imageContainer}
      <span className={style.textContainer}>
        {hasRelatedPrefix && <b>Related: </b>}
        {title}
      </span>
    </a>
  );
};

/**
 * This is our related article component
 */
const RelatedArticle = (props: RelatedArticleProps): React.ReactElement => {
  const { stories, displayImage = true, headerText, hasRelatedPrefix } = props;

  return (
    <div className={style.relatedArticle}>
      {headerText && <p className={style.headerText}>{headerText}</p>}
      {stories.map((item, index) => {
        return (
          <RelatedArticleItem
            key={index}
            item={item}
            displayImage={displayImage}
            imageErrorSrc={props.imageErrorSrc}
            hasRelatedPrefix={hasRelatedPrefix}
          />
        );
      })}
    </div>
  );
};

export default RelatedArticle;
