export default function CloseIcon({
  size = 16,
  strokeWidth = 2,
}: {
  size?: number;
  strokeWidth?: number;
}) {
  const x0 = strokeWidth / 2;
  const x1 = size - x0;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke="currentColor"
    >
      <path
        d={`M${x0} ${x0}L${x1} ${x1}`}
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d={`M${x1} ${x0}L${x0} ${x1}`}
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
