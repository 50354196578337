import cx from "classnames";
import {
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Arrow from "../../Element/Arrow/Arrow";
import styles from "./naturalCarousel.module.css";
import { goToPosition } from "./utils";

export type ArrowVariant = "drop" | "gradient" | "circle";

type Props = {
  children: ReactNode;
  isLooping: boolean;
  // To forward the position change to parent elements.
  onPositionChange?: (newPosition: number) => void;
  arrowVariant?: ArrowVariant;
  arrowSize?: number;
  showArrowsOnMobile?: boolean;
  centerItems?: boolean;
};

export type NaturalCarouselRef = {
  previous: () => void;
  next: () => void;
  goTo: (positionProp: number) => void;
};

/**
 * Utility NaturalCarousel Component.
 * Navigation can be done by both navigation buttons on the sides or natural scrolling.
 */
const NaturalCarousel = forwardRef<NaturalCarouselRef, Props>(
  (
    {
      children,
      isLooping,
      onPositionChange,
      arrowVariant,
      arrowSize,
      showArrowsOnMobile,
      centerItems,
    },
    handlerRef,
  ): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const onPositionChangeRef =
      useRef<Props["onPositionChange"]>(onPositionChange);
    const timeoutSnap = useRef<NodeJS.Timeout>();
    const timeoutPosition = useRef<NodeJS.Timeout>();

    const [position, setPosition] = useState(0);
    const [hasSnap, setHasSnap] = useState(true);

    // init with undefined until calculated
    const [hasPrevious, setHasPrevious] = useState<boolean | undefined>(
      isLooping || undefined,
    );
    const [hasNext, setHasNext] = useState<boolean | undefined>(
      isLooping || undefined,
    );

    useEffect(() => {
      if (!hasSnap) {
        clearTimeout(timeoutSnap.current);
        timeoutSnap.current = setTimeout(() => {
          setHasSnap(true);
          // Approximate speed of scrollTo smooth in most browsers
        }, 750);
        return () => clearTimeout(timeoutSnap.current);
      }
      // position is still needed here in the event user is spam clicking on the arrow
    }, [hasSnap, position]);

    const handleClickArrow = (direction: "previous" | "next") => {
      setHasSnap(false);
      createHandler(direction)();
    };

    const createHandler = useCallback(
      (positionChange: number | "next" | "previous") => {
        return () => {
          if (!ref.current) return;
          const newState = goToPosition({
            container: ref.current,
            currentPosition: position,
            newPosition: positionChange,
            isLooping,
          });

          // Not needed as onScrollAndResize is ALWAYS listening to scroll events.
          // setPosition(newState.newPosition);
          // setHasPrevious(newState.hasPrevious);
          // setHasNext(newState.hasNext);

          if (onPositionChangeRef.current && newState) {
            onPositionChangeRef.current(newState.newPosition);
          }
        };
      },
      [position, isLooping],
    );

    useImperativeHandle(handlerRef, () => {
      return {
        previous: createHandler("previous"),
        next: createHandler("next"),
        goTo(newPosition: number) {
          createHandler(newPosition)();
        },
      };
    }, [createHandler]);

    // To make sure navigation arrows appear or disappear accordingly natural scroll or window resize is performed
    const onScrollAndResize = useCallback(() => {
      if (!ref.current) return;
      const numberOfChildren = ref.current.children.length;
      const containerRect = ref.current.getBoundingClientRect();
      const leftMostChildRect =
        ref.current.children[0]?.getBoundingClientRect();
      const rightMostChildRect =
        ref.current.children[numberOfChildren - 1]?.getBoundingClientRect();
      if (!leftMostChildRect || !rightMostChildRect) {
        return;
      }
      if (!isLooping) {
        // giving 1px allowance for edge cases
        setHasPrevious(containerRect.left - leftMostChildRect.left > 1);
        setHasNext(rightMostChildRect.right - containerRect.right > 1);
      } else {
        setHasPrevious(true);
        setHasNext(true);
      }

      // Wrapping a debounce around POSITION change to prevent flickering of active item
      clearTimeout(timeoutPosition.current);
      timeoutPosition.current = setTimeout(() => {
        const currPos = Math.floor(
          (containerRect.left - leftMostChildRect.left) /
            leftMostChildRect.width,
        );
        setPosition(currPos);
        if (onPositionChangeRef.current) {
          onPositionChangeRef.current(currPos);
        }
      }, 200);
    }, [isLooping]);

    useEffect(() => {
      if (!ref.current) return;
      const currentContainer = ref.current;
      currentContainer.addEventListener("scroll", onScrollAndResize, {
        passive: true,
      });
      window.addEventListener("resize", onScrollAndResize, {
        passive: true,
      });
      onScrollAndResize();
      return () => {
        currentContainer.removeEventListener("scroll", onScrollAndResize);
        window.removeEventListener("resize", onScrollAndResize);
      };
    }, [onScrollAndResize]);

    return (
      <div className={styles.naturalContainer}>
        <div
          ref={ref}
          className={cx(
            styles.naturalItems,
            hasSnap && styles.snap,
            // only add justifyCenter when certain no scroll needed
            centerItems &&
              hasPrevious === false &&
              hasNext === false &&
              styles.justifyCenter,
          )}
        >
          {children}
        </div>

        <button
          className={cx(
            styles.prev,
            !hasPrevious && styles.hidden,
            !showArrowsOnMobile && styles.hideOnMobile,
            arrowVariant === "drop" && styles.dropShadow,
            arrowVariant === "gradient" && styles.gradientBg,
            arrowVariant === "circle" && styles.circleAround,
          )}
          onClick={() => handleClickArrow("previous")}
        >
          <Arrow direction="left" size={arrowSize} />
        </button>

        <button
          className={cx(
            styles.next,
            !hasNext && styles.hidden,
            !showArrowsOnMobile && styles.hideOnMobile,
            arrowVariant === "drop" && styles.dropShadow,
            arrowVariant === "gradient" && styles.gradientBg,
            arrowVariant === "circle" && styles.circleAround,
          )}
          onClick={() => handleClickArrow("next")}
        >
          <Arrow size={arrowSize} />
        </button>
      </div>
    );
  },
);

export default NaturalCarousel;
