import ToolTip from "../../Block/ToolTip";

export const newsletterConsentText = (lang: string, classname?: string) => {
  if (lang == "zh") {
    return (
      <>
        是的，我想要收到新报业媒体有限公司{" "}
        <ToolTip text="新报业媒体有限公司相关公司、附属机构以及代理和授权服务提供商。">
          ⓘ
        </ToolTip>{" "}
        的相关促销信息。透过提交上述资料并点击“SIGN
        UP”，我理解并接受新报业媒体因时而更改的{" "}
        <a
          href="https://www.sph.com.sg/tnc/website"
          className={classname}
          target="_blank"
        >
          条款和条件
        </a>{" "}
        以及{" "}
        <a
          href="https://www.sph.com.sg/tnc/privacy"
          className={classname}
          target="_blank"
        >
          隐私条款
        </a>
        。
      </>
    );
  } else {
    return (
      <>
        Yes, I would also like to receive SPH Media Group&apos;s{" "}
        <ToolTip text="SPH Media Limited, its related corporations and affiliates as well as their agents and authorised service providers.">
          ⓘ
        </ToolTip>{" "}
        marketing and promotions.
      </>
    );
  }
};

export const newsletterTermsText = (lang: string, classname?: string) => {
  if (lang == "zh") {
    return "";
  } else {
    return (
      <>
        By submitting my information above and clicking &apos;SIGN UP&apos;, I
        confirm I understand and accept{" "}
        <a
          href="https://www.sph.com.sg/tnc/website"
          className={classname}
          target="_blank"
        >
          SPH Media Terms & Conditions
        </a>{" "}
        and{" "}
        <a
          href="https://www.sph.com.sg/tnc/privacy"
          className={classname}
          target="_blank"
        >
          Privacy Policy
        </a>{" "}
        as amended from time to time.
      </>
    );
  }
};
