import { configSite } from "@pub/config";

import { CUESection } from "../types/Opensearch";
import { StoryDataSection } from "../types/ServerCustomContext";
import { splitTextENZH } from "./splitTextENZH";

export const sectionUniqNameToPath = (uniqueName: string) => {
  // Lets build our section path. By default section un coming from
  // caas split using _ instead of /. For e.g fashion_news-trends
  return "/" + uniqueName.split("_").join("/");
};

export const mapSection = ({
  name,
  uniqueName,
  parent,
}: CUESection): StoryDataSection => {
  const returnValue = {
    uniqueName,
    name,
    path: sectionUniqNameToPath(uniqueName || ""),
    parent: parent ? mapSection(parent) : undefined,
  };
  if (configSite.splitZHSectionName) {
    const { textEN, textZH } = splitTextENZH(name);
    return {
      ...returnValue,
      name: textEN,
      nameZH: textZH,
    };
  }
  return returnValue;
};

export const extractSectionInfo = (
  sections: StoryDataSection[],
  uniqSectionName: string,
): StoryDataSection | undefined => {
  const sectionInfo = sections.find((s) => s.uniqueName === uniqSectionName);
  if (sectionInfo) {
    return sectionInfo;
  }
  const parents = sections
    .map((section) => section.parent)
    .filter((section): section is StoryDataSection => !!section);
  if (parents.length) {
    return extractSectionInfo(parents, uniqSectionName);
  }
  return;
};
