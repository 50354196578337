import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
  trendingBlock?: boolean;
};

/**
 * Description: ContentBlock 5 items | Content Block A (5 tiles)
 */
export default function HomeContentBlockJ({
  data,
  alternateBg,
  dataSource,
  trendingBlock,
}: Props): ReactElement {
  const ContentBlockThreeItems = extractHomeContentData(data, dataSource);

  const cta =
    trendingBlock == false
      ? {
          name: configHome.contentBlockJ?.viewMore?.text || "VIEW ALL",
          url: ContentBlockThreeItems?.url || "",
          icon: configHome.viewMore?.icon,
        }
      : undefined;

  return (
    <div
      className={cx(
        styles.gutter,
        styles.contentBlockJContainer,
        alternateBg && styles.backgroundFill,
        trendingBlock && styles.trendingAltStyle,
      )}
    >
      {!!ContentBlockThreeItems?.items.length && (
        <div className={cx(styles.bodyContainer)}>
          <ContentBlock
            inlineGutter={false}
            heading={ContentBlockThreeItems.title}
            cta={cta}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: ContentBlockThreeItems.uniqSectionName,
              }}
            >
              <div className={styles.contentBlockFourItemsContainer}>
                {ContentBlockThreeItems.items.map((item, index) => {
                  return (
                    <div key={index} className={styles.contentBlockFourItems}>
                      {index === 0 ? (
                        <div className={styles.bodyContainerJ}>
                          <AnchorBlock
                            {...item}
                            loading="lazy"
                            placeholder={placeholders.landscape}
                            mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                          />
                        </div>
                      ) : (
                        <div
                          className={cx(
                            styles.mDownGutterSides,
                            styles.latestArticleJ,
                          )}
                        >
                          <LatestArticle
                            {...item}
                            mode="landscape"
                            placeholders={placeholders}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      )}
    </div>
  );
}
