import { ReactNode } from "react";
import * as Typography from "../../Element/Typography";
import BreadCrumbs from "../../Element/BreadCrumb";
import ResponsiveImage from "../../Element/ResponsiveImage";
//import css
import styles from "./exploremore.module.css";
import { BaseTeaserData, ImageCrop } from "../../Utility/dataTypes";
import { SummaryItemSmall } from "../../Block/SummaryItem/SummaryItem";

const { Heading } = Typography;

type ExploreMoreProps = {
  headingText: string;
  items: BaseTeaserData[];
  placeholder?: ImageCrop;
};

/**
 * This is explore more component which can be found mostly in
 * the right sidebar
 *
 */
function ExploreMore({
  headingText,
  items,
  placeholder,
}: ExploreMoreProps): ReactNode {
  return (
    <div className={styles.exploreMoreCard}>
      <Heading.SubHeading3>{headingText}</Heading.SubHeading3>

      {items.map((item, index) => {
        const imageData = item.image?.crops.original || placeholder;
        return (
          <div key={index} className={styles.cardBody}>
            {imageData && (
              <div className={styles.cardImage}>
                <a href={item.path}>
                  <ResponsiveImage
                    {...imageData}
                    displayWidth={105}
                    errorSrc={placeholder?.src}
                  />
                </a>
              </div>
            )}
            <div className={styles.cardSummary}>
              <SummaryItemSmall
                beforeTitle={<BreadCrumbs sections={item.sections} />}
                title={item.title}
                url={item.path}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ExploreMore;
