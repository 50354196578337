import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

/**
 * Description: Content Block G (4 tiles with IMU)
 */
export default function HomeContentBlockG({
  data,
  dataSource,
  alternateBg,
}: Props): ReactNode {
  const contentBlockG = extractHomeContentData(data, dataSource);

  return (
    contentBlockG && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          styles.gutter,
          styles.contentBlockGContainer,
        )}
      >
        <div className={cx(styles.bodyContainer)}>
          <ContentBlock
            heading={contentBlockG.title}
            headingZH={contentBlockG.titleZH}
            cta={{
              name: configHome.contentBlockF?.viewMore?.text || "VIEW ALL",
              url: contentBlockG.url,
              icon: configHome.viewMore?.icon,
            }}
            imu={!configHome.contentBlockG?.hideIMU && <Ads.AdsSideBar />}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: configHome.contentBlockG?.skipSectionContext
                  ? undefined
                  : contentBlockG.uniqSectionName,
              }}
            >
              <div className={cx(styles.latestTeaserFontOverride, styles.row)}>
                {contentBlockG.items.map((item, index) => {
                  return (
                    <div key={index} className={cx(styles.column)}>
                      <LatestArticle
                        {...item}
                        mode={
                          configHome.contentBlockG?.landscapeIndexes?.includes(
                            index,
                          )
                            ? "landscape"
                            : "portrait"
                        }
                        placeholders={placeholders}
                        landscapeImageWidth={
                          configHome.contentBlockG?.landscapeImageWidth || 315
                        }
                        portraitImageWidth={
                          configHome.contentBlockG?.portraitImageWidth || 315
                        }
                        displayPublishDate={configTeaser.publishDate}
                      />
                    </div>
                  );
                })}
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
