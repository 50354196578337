// Lightbox.tsx
import { useCarousel } from "../../../hooks";
import ResponsiveIframe from "../../Element/ResponsiveIframe";
import * as Utility from "../../Utility";
import * as Ads from "../../Utility/Ads/Ads";
import * as Typography from "../../Element/Typography";
import { insertImuAt } from "../../Utility/Items/Items";
import styles from "./lightbox.module.css";
import { ReactNode, useEffect, useState } from "react";
import { ImageCrop } from "../../Utility/dataTypes";
import CloseIcon from "../../Element/CloseIcon/CloseIcon";
import Arrow from "../../Element/Arrow/Arrow";

const { Body, Pagination } = Typography;

type Props = {
  isOpen: boolean;
  gotoItem: number;
  toggle: () => void;
  items: Array<{
    videoUrl?: string;
    image?: ImageCrop;
    alt: string;
    caption: string;
    targetUrl?: string;
    shareUrl?: string;
    type?: string;
    position?: number;
    srcWidth?: number;
    srcHeight?: number;
  }>;
  ImuInsertAt?: number;
  // Imu: ReactNode;
  onSlideChange?: (arg0: number, arg1: string) => void;
  onSlideClick?: (arg0: number, arg1: string) => void;
};

export default function Lightbox({
  isOpen,
  items,
  // Imu,
  ImuInsertAt = 2,
  gotoItem,
  toggle,
  onSlideChange = () => {},
  onSlideClick = () => {},
}: Props): ReactNode {
  const itemsWithImu = insertImuAt(items, ImuInsertAt);
  const { next, prev, refPassthrough, position, goto, TouchHandlers } =
    useCarousel(itemsWithImu.length, 1, false);

  const [isAds, setIsAds] = useState(false);

  //check if the current item is an ad
  useEffect(() => {
    if (itemsWithImu[position]?.type === "ads") {
      setIsAds(true);
    } else {
      setIsAds(false);
    }
  }, [itemsWithImu, position]);

  useEffect(
    () => {
      goto(itemsWithImu.findIndex((item) => item.position === gotoItem));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gotoItem, isOpen],
  );

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        isOpen && toggle();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [isOpen, toggle]);

  const galleryContainerNavigation = (
    <>
      {position > 0 && (
        <button
          className={styles.prev}
          onClick={() => {
            prev();
            onSlideChange(position - 1, "prev");
          }}
        >
          <Arrow direction="left" size={54} />
        </button>
      )}
      {position < itemsWithImu.length - 1 && (
        <button
          className={styles.next}
          onClick={() => {
            next();
            onSlideChange(position + 1, "next");
          }}
        >
          <Arrow direction="right" size={54} />
        </button>
      )}
    </>
  );

  const paginationLabel = `${itemsWithImu[position]?.position ?? "" + 1}/${
    items.length
  }`;

  const caption = itemsWithImu[position]?.targetUrl ? (
    <a href={itemsWithImu[position]?.targetUrl}>
      {itemsWithImu[position]?.caption}
    </a>
  ) : (
    itemsWithImu[position]?.caption
  );

  return (
    <div
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div className={styles.lightbox}>
        <div
          className={styles.lightboxContent}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={() => {
              toggle();
            }}
            className={styles.closeButton}
          >
            <CloseIcon size={33} />
          </button>
          <div
            style={{ width: window.innerWidth }}
            className={styles.embeddedgalleryContainer}
            {...TouchHandlers}
            ref={refPassthrough}
          >
            {itemsWithImu?.map((item, index: number) => (
              <div
                key={index}
                className={styles.embeddedgalleryItemContainer}
                onClick={() => {
                  onSlideClick(position, "click");
                }}
              >
                <div className={styles.videoWrapper}>
                  {item.type === "ads" ? (
                    <Ads.AdsGallerylightboxItemImu />
                  ) : item.videoUrl ? (
                    <ResponsiveIframe src={item.videoUrl} loading="lazy" />
                  ) : (
                    <Utility.Image
                      src={item.image?.src || ""}
                      alt={item.alt}
                      width={"100%"}
                      height={"100%"}
                      objectFit="contain"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.lightBoxNavigation}>
            {galleryContainerNavigation}
          </div>
        </div>
      </div>
      <div
        className={styles.descriptionContainer}
        style={{
          visibility: isAds ? "hidden" : "visible",
        }}
      >
        <Pagination size="large">
          <span className={styles.paginationLabel}>{paginationLabel}</span>
        </Pagination>
        <Body>{caption}</Body>
      </div>
    </div>
  );
}
