import { ReactNode } from "react";

import CloseIcon from "../../Element/CloseIcon/CloseIcon";
import styles from "./MobileBanner.module.css";

type Props = {
  href: string;
  children: ReactNode;
  onClose?: () => void;
};

export default function MobileBanner({ href, children, onClose }: Props) {
  return (
    <div className={styles.container}>
      <a className={styles.link} href={href}>
        {children}
      </a>
      {onClose && (
        <button className={styles.close} onClick={onClose}>
          <CloseIcon size={12} strokeWidth={1} />
        </button>
      )}
    </div>
  );
}
