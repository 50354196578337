import CategoryLayout from "@app/layouts/Category/CategoryLayout";
import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import { SectionPageData } from "@app/types/ServerCustomContext";
import AdSettings from "@app/utils/AdSettings";
import {
  GA4_REF_CODE_ANCHORBLOCK_B,
  GA4_REF_CODE_CONTENTBLOCK_L,
  GA4_REF_CODE_TITLE_STACKED,
} from "@app/utils/ga/ref-code";
import { getContentBlockRefCode } from "@app/utils/ga/tracking";
import {
  createTeaserMapper,
  mapTeaserDataToAnchor,
  mapTeaserDataToLatestProps,
} from "@app/utils/mapTeaserDataToProps";
import MetaData from "@components/Modules/MetaData";
import { mapSectionDataToMetaData } from "@components/Modules/MetaData/mappers";
import PubBaseLayout from "@pub/BaseLayout";
import { configCategory, configTrackingCode } from "@pub/config";
import { GlobalBaseData, SectionContext } from "@sphtech/dmg-design-system";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { useLocation } from "react-router-dom";

export default function Category() {
  const routeContext: CustomContext<
    TRouteWithRedirect<SectionPageData & GlobalBaseData, string>
  > = useRouteContext();

  // Context from server.ts
  const { context: response } = routeContext;
  const location = useLocation();

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const sectionData = response.payload;
  const topStory = sectionData.topStories[0];
  const userSubmissionBox =
    response.payload.userSubmissionBox?.showOn.sectionPaths?.includes(
      sectionData.path,
    )
      ? response.payload.userSubmissionBox
      : undefined;

  const metaData = mapSectionDataToMetaData(sectionData);
  return (
    <SectionContext.Provider
      value={{ uniqueName: sectionData.uniqSectionName }}
    >
      <PubBaseLayout>
        <MetaData {...metaData} />
        <AdSettings
          pageType="category"
          sectionName={metaData.title}
          adUnits="lb1,lb2,imu1,imu2,catfish,prestitial"
          pageTitle={metaData.title}
          currentLocation={location}
        />

        <CategoryLayout
          data={{
            uniqSectionName: sectionData.uniqSectionName,
            name: sectionData.name,
            nameZH: sectionData.nameZH,
            subHeading: sectionData.parent,
            anchorBlock: topStory
              ? mapTeaserDataToAnchor(topStory, GA4_REF_CODE_ANCHORBLOCK_B)
              : undefined,
            latestArticle: sectionData.topStories
              .slice(1)
              .map((teaser) =>
                mapTeaserDataToLatestProps(teaser, GA4_REF_CODE_TITLE_STACKED),
              ),
            mainContentBlock: sectionData.listedStories.map((teaser) =>
              mapTeaserDataToLatestProps(
                teaser,
                configTrackingCode.category?.mainContentBlock ||
                  GA4_REF_CODE_CONTENTBLOCK_L,
              ),
            ),
            listGroup: sectionData.listGroup,
            videoGallery: {
              title: sectionData.videoGallery?.title || "",
              teasers: sectionData.videoGallery?.teasers
                ? sectionData.videoGallery.teasers.map(
                    createTeaserMapper({
                      gaRefCode: getContentBlockRefCode("videoplaylist"),
                    }),
                  )
                : [],
            },
            showVerticalLine: configCategory.showVerticalLine,
            userSubmissionBox: userSubmissionBox,
          }}
        />
      </PubBaseLayout>
    </SectionContext.Provider>
  );
}
