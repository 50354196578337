type DateTimeFormat = {
  [key: string]: string | number;
};

const dateTime: DateTimeFormat = {
  AMPM: "AM",
  Hrs: 12,
  Min: "00",
  mm: 1,
  mname: "Jan",
  dd: 1,
  yy: "21",
  yyyy: 2021,
  timezone: "UTC",
};

type DateTimeMap = {
  [K in keyof DateTimeFormat]: string;
};

const datetimeFormatter = function (date: Date, format: string): string {
  if (date === null || date === undefined) {
    return "";
  }
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const map: DateTimeMap = {
    AMPM: date.getHours() >= 12 ? "PM" : "AM",
    Hrs: (function () {
      // Find current hour in AM-PM Format
      let hours = date.getHours() % 12;

      // To display "0" as "12"
      hours = hours ? hours : 12;
      return hours.toString();
    })(),
    Min: date.getMinutes().toString().padStart(2, "0"),
    mm: `${date.getMonth() + 1}`,
    mname: monthNames[date.getMonth()] || "",
    dd: date.getDate().toString(),
    yy: date.getFullYear().toString().slice(-2),
    yyyy: date.getFullYear().toString(),
    zpm: (date.getMonth() + 1).toString().padStart(2, "0"),
    zpd: date.getDate().toString().padStart(2, "0"),
  };

  return format.replace(
    /mm|dd|mname|yyyy|AMPM|Hrs|Min|zpm|zpd/gi,
    (matched) => {
      return map[matched] || "";
    },
  );
};

export { dateTime, datetimeFormatter };
