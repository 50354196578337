import { Loader, UserSubmissionBox } from "@sphtech/dmg-design-system";
import { useInView } from "@src/app/components/hooks/hook";
import { TeaserDataApiResponse } from "@src/app/types/ServerCustomContext";
import { GA4_REF_CODE_CONTENTBLOCK_L } from "@src/app/utils/ga/ref-code";
import { mapTeaserDataToLatestProps } from "@src/app/utils/mapTeaserDataToProps";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
import { ReactNode, useEffect } from "react";
import useSWRInfinite from "swr/infinite";

import CategoryStoryListingLayout from "./CategoryStoryListingLayout";

type Props = {
  data: {
    tagSlug?: string;
    uniqSectionName?: string;
    limit: number;
    ignoreIds: string[];
    lb1?: ReactNode;
    imu?: ReactNode;
    userSubmissionBox?: UserSubmissionBox;
  };
};

export default function CategoryStoryListing({ data }: Props): ReactNode {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const limit = data.limit;

  const getKey = (pageIndex: number) => {
    return `/_plat/api/teaser/latest?${[
      data.uniqSectionName &&
        `uniqSectionNames=${encodeURIComponent(data.uniqSectionName)}`,
      data.tagSlug && `tags=${encodeURIComponent(data.tagSlug)}`,
      `ignoreIds=${encodeURIComponent(data.ignoreIds.join(","))}`,
      `from=${pageIndex * limit}`,
      `size=${limit}`,
    ]
      .filter(Boolean)
      .join("&")}`;
  };

  const {
    data: moreData,
    isLoading,
    size,
    setSize,
  } = useSWRInfinite<TeaserDataApiResponse>(getKey, {
    revalidateFirstPage: false,
    initialSize: 0,
  });

  const sizeLength = size - 1;
  const isLoadingMore =
    isLoading ||
    (size > 0 && moreData && typeof moreData[sizeLength] === "undefined");

  const hasMoreData = size === 0 || !!moreData?.[size - 1]?.results?.length;

  useEffect(() => {
    if (!inView || !hasMoreData) return;
    void setSize((prev) => {
      window.SPHMGTM.cmd.push(`pagination=${prev + 1}`);
      return prev + 1;
    });
  }, [inView, setSize, hasMoreData]);

  return (
    <>
      {moreData &&
        moreData.length > 0 &&
        moreData.map((el, index) => {
          if (!el?.results?.length) {
            return;
          }

          const elem = el.results.map((teaser) =>
            mapTeaserDataToLatestProps(teaser, GA4_REF_CODE_CONTENTBLOCK_L),
          );

          const exploreMore = data.uniqSectionName
            ? {
                uniqSectionName: data.uniqSectionName,
                // send first potentially curated page as ignore ids
                ignoreIds: data.ignoreIds.join(","),
                // search after end cursor of current page
                searchAfter: el.endCursor,
              }
            : undefined;

          return (
            <CategoryStoryListingLayout
              key={index}
              data={{
                exploreMore,
                mainContentBlock: elem,
                lb1: data.lb1,
                imu: data.imu,
                userSubmissionBox:
                  index === 0 ? data.userSubmissionBox : undefined,
              }}
            />
          );
        })}
      <div ref={ref}>{isLoadingMore && <Loader label={LOAD_MORE_TEXT} />}</div>
    </>
  );
}
