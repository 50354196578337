import { useEffect, useRef, useState } from "react";

type StickyNavbarProps = {
  threshold?: number;
};

/**
 * Copied over from app/components/hooks/hook.tsx
 * Used by Base layout
 */
export default function useSticky({ threshold = 0 }: StickyNavbarProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const navbarRef = ref.current;
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (!entry) {
          return;
        }

        if (entry.isIntersecting || entry.intersectionRatio >= threshold) {
          setSticky(false);
          navbarRef?.style.setProperty("visiblity", "none");
        } else {
          setSticky(true);
          navbarRef?.style.setProperty("visiblity", "hidden");
        }
      },
      { threshold },
    );

    if (navbarRef) {
      observer.observe(navbarRef);
    }

    return () => {
      if (navbarRef) {
        observer.unobserve(navbarRef);
      }
    };
  }, [threshold]);

  return { refPassthrough: ref, sticky };
}
