/**
 * Self Executing Function for HTML Head to instantly hide mobile banner based on cookie
 * - can be toString injected into head and should therefore not contain access anything outside of scope like COOKIE_NAME
 */
export function hideMobileBannerSEF() {
  if (document.cookie.includes("sphMagMobileBanner=0")) {
    document.documentElement.setAttribute("data-hide-mobile-banner", "");
  }
}

function setCookie(name: string, value: string, expiresDays: number) {
  const expires = new Date();
  expires.setTime(expires.getTime() + expiresDays * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

// make sure to also change manually in hideMobileBannerSEF above
const COOKIE_NAME = "sphMagMobileBanner";

export function setMobileBannerHiddenCookie(hiddenDays: number) {
  setCookie(COOKIE_NAME, "0", hiddenDays);
  // also immediately hide it
  document.documentElement.setAttribute("data-hide-mobile-banner", "");
}
