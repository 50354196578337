import { GlobalBaseData } from "@sphtech/dmg-design-system";
import {
  CustomContext,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import ArticleLayout from "@src/app/layouts/Article/ArticleLayout";
import ErrorLayout from "@src/app/layouts/ErrorLayout/ErrorLayout";
import { StoryData } from "@src/app/types/ServerCustomContext";

export function StoryPage() {
  const routeContext: CustomContext<
    TRouteWithRedirect<(StoryData & GlobalBaseData) | null, string>
  > = useRouteContext();
  const { context: response } = routeContext;

  if (response.statusCode !== 200 || !response.payload) {
    return <ErrorLayout response={response} />;
  }

  const userSubmissionBox =
    response.payload.userSubmissionBox?.showOn.storiesWithTagSlugs?.some(
      (tagSlug) => response.payload?.tags.some((tag) => tag.slug === tagSlug),
    )
      ? response.payload.userSubmissionBox
      : undefined;

  return (
    <ArticleLayout
      storyData={response.payload}
      userSubmissionBox={userSubmissionBox}
    />
  );
}
