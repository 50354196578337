import {
  extractCrop,
  ResolvedGalleryItemHtmlInfoCUEElement,
  ResolvedGalleryItemImageCUEElement,
} from "@app/layouts/Article/elements";
import styles from "@app/layouts/Article/Storyline.module.css";
import { Annotation } from "@app/layouts/Article/StorylineElements/Annotation/Annotation";
import { fullScreenIcon, ShareIcon } from "@app/theme/icons/commonIcons";
import { configSingleStory, configSite } from "@pub/config";
import {
  EmbeddedAction,
  EmbeddedImage,
  Typography,
} from "@sphtech/dmg-design-system";
import { forwardRef, ReactNode, Ref } from "react";

import GalleryItemTitle from "./GalleryItemTitle";

const { Body, Disclaimer } = Typography;

type GalleryItemProps = {
  info?:
    | ResolvedGalleryItemImageCUEElement
    | ResolvedGalleryItemHtmlInfoCUEElement;
  responsiveIframe?: ReactNode;
  total: number;
  currentItem: number;
  fullScreen: () => void;
  fullScreenImage?: () => void;
  path: string;
  ref?: Ref<HTMLDivElement>;
};

const GalleryItem = forwardRef<HTMLDivElement, GalleryItemProps>(
  function GalleryItemFn(
    {
      info,
      responsiveIframe,
      currentItem,
      total,
      fullScreen,
      fullScreenImage,
      path,
    },
    ref,
  ) {
    const picture =
      info?.type === "gallery_items_image" ? info.relation : undefined;
    const crop = extractCrop(picture, "original");
    const paginationLabel = `${currentItem}${configSingleStory.gallery?.paginationSeparator || " of "}${total}`;

    const creditText = (prefix?: string, text?: string) => {
      return (
        (text && prefix && !text.startsWith(prefix) && `${prefix}${text}`) ||
        text
      );
    };

    return (
      <div className={styles.galleryImage} ref={ref}>
        {responsiveIframe ? (
          <div className={styles.embeddedHtmlContainer}>
            <GalleryItemTitle info={info} />

            {responsiveIframe}

            <EmbeddedAction
              caption={
                <Disclaimer>
                  {creditText(
                    configSingleStory.caption?.prefix,
                    info?.additionalFields.credit?.value,
                  )}
                </Disclaimer>
              }
              pageinationLabel={paginationLabel}
              onFullScreen={fullScreen}
              onFullScreenImage={fullScreenImage}
              fullScreenIcon={fullScreenIcon}
              shareIcon={ShareIcon}
              shareUrl={info?.additionalFields.targetUrl?.value ?? "/"}
              shareMedia={configSingleStory.gallery?.galleryItemShareMedia}
            />
          </div>
        ) : crop ? (
          <EmbeddedImage
            key={currentItem}
            crop={crop}
            heading={<GalleryItemTitle info={info} />}
            actionBar={{
              caption: (
                <Disclaimer>
                  {creditText(
                    configSingleStory.caption?.prefix,
                    info?.additionalFields.credit?.value ||
                      picture?.fields.credit,
                  )}
                </Disclaimer>
              ),
              pageinationLabel: paginationLabel,
              onFullScreen: fullScreen,
              onFullScreenImage: fullScreenImage,
              fullScreenIcon: fullScreenIcon,
              shareIcon: ShareIcon,
              shareUrl: `${configSite.address}${path}?slide=${currentItem}`,
              shareMedia: configSingleStory.gallery?.galleryItemShareMedia,
            }}
          />
        ) : null}
        {info?.additionalFields.description?.value && (
          <Body>
            <Annotation element={info.additionalFields.description} />
          </Body>
        )}
      </div>
    );
  },
);

export default GalleryItem;
