import { Ads } from "@sphtech/dmg-design-system";

import HomeAffiliateProduct from "./HomeAffiliateProduct";
import HomeAnchorBlock from "./HomeAnchorBlock";
import HomeColumnSideScroll from "./HomeColumnSideScroll";
import HomeContentBlockA from "./HomeContentBlockA";
import HomeContentBlockB from "./HomeContentBlockB";
import HomeContentBlockD from "./HomeContentBlockD";
import HomeContentBlockF from "./HomeContentBlockF";
import HomeContentBlockG from "./HomeContentBlockG";
import HomeContentBlockGridAreas from "./HomeContentBlockGridAreas";
import HomeContentBlockI from "./HomeContentBlockI";
import HomeContentBlockJ from "./HomeContentBlockJ";
import HomeContentBlockK from "./HomeContentBlockK";
import HomeContentBlockL from "./HomeContentBlockL";
import HomeLatestStory from "./HomeLatestStory";
import HomeMagazineListing from "./HomeMagazineListing";
import HomeNewsletter from "./HomeNewsletter";
import HomeSocialVideoPortrait from "./HomeSocialVideoPortrait";
import HomeVideoGalleryLandscape from "./HomeVideoGalleryLandscape";

export const elementsWithoutDataSource = {
  adUnit: Ads.AdsMidContent,
  magazineListing: HomeMagazineListing,
  newsletter: HomeNewsletter,
};

export const elementsWithDataSource = {
  anchorBlock: HomeAnchorBlock,
  affiliateProduct: HomeAffiliateProduct,
  columnSideScroll: HomeColumnSideScroll,

  contentBlockA: HomeContentBlockA,
  contentBlockB: HomeContentBlockB,
  contentBlockD: HomeContentBlockD,
  contentBlockF: HomeContentBlockF,
  contentBlockG: HomeContentBlockG,
  contentBlockJ: HomeContentBlockJ,
  contentBlockK: HomeContentBlockK,
  contentBlockL: HomeContentBlockL,
  contentBlockI: HomeContentBlockI,

  contentBlockGridAreas: HomeContentBlockGridAreas,

  latestArticle: HomeLatestStory,
  socialVideoPortrait: HomeSocialVideoPortrait,
  videoGalleryLandscape: HomeVideoGalleryLandscape,
};
