import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

/**
 * Description: Content Block left side AnchorBlock, right side 2-col masonry layout
 * Edited from HomeContentBlockL
 */
export default function HomeContentBlockI({
  data,
  dataSource,
  alternateBg,
}: Props): ReactNode {
  const contentBlockI = extractHomeContentData(data, dataSource);

  if (!contentBlockI?.items[0]) return null;

  return (
    <div
      className={cx(
        alternateBg && styles.backgroundFill,
        alternateBg && styles.gutterTopBottom,
        styles.contentHubBlockIContainer,
      )}
    >
      <div
        className={cx(
          styles.bodyContainer,
          styles.gutter,
          styles.contentBlockOverrideColor,
        )}
      >
        <ContentBlock
          inlineGutter={false}
          heading={contentBlockI.title}
          headingZH={contentBlockI.titleZH}
          cta={{
            name: "VIEW ALL",
            url: contentBlockI.url,
            icon: configHome.viewMore?.icon,
          }}
          withDivider={configHome.headingDivider}
          isHeadingClickable={configHome.isHeadingClickable}
        >
          <SectionContext.Provider value={{ uniqueName: undefined }}>
            <div className={styles.flex}>
              <div className={cx(styles.latestArticleContainer)}>
                <AnchorBlock
                  {...contentBlockI.items[0]}
                  loading="lazy"
                  placeholder={placeholders.landscape}
                  mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                />
              </div>

              <div className={styles.masonryLayout}>
                {contentBlockI.items.slice(1).map((item, index) => {
                  return (
                    <div key={index} className={styles.masonryItem}>
                      <LatestArticle
                        {...item}
                        mode="original"
                        placeholders={placeholders}
                        displayPublishDate={configTeaser.publishDate}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </SectionContext.Provider>
        </ContentBlock>
      </div>
    </div>
  );
}
