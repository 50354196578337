import { hideMobileBannerSEF } from "@sphtech/dmg-design-system";
import { Helmet } from "react-helmet-async";

export default function HideMobileBanner() {
  return (
    <Helmet>
      <script>{`(${hideMobileBannerSEF.toString()})()`}</script>
    </Helmet>
  );
}
