import { whiteArrowLeft, whiteArrowRight } from "@app/theme/icons/commonIcons";
import { ContentHubData } from "@app/types/ServerCustomContext";
import { GA4_REF_CODE_CONTENTBLOCK_L } from "@app/utils/ga/ref-code";
import { mapTeaserDataToLatestProps } from "@app/utils/mapTeaserDataToProps";
import { configContentHub, configSite, configTrackingCode } from "@pub/config";
import {
  Ads,
  CallOnQuote,
  PageShare,
  PortraitScroll,
  ResponsiveImage,
  Typography,
} from "@sphtech/dmg-design-system";
import { NoSSR } from "@sphtech/web2-core/components";
import cx from "classnames";
import { Fragment, ReactNode } from "react";

import { Deals } from "../Article/Deals";
import styles from "./contenthub.module.css";
import ContentHubStoryListing from "./ContentHubStoryListing";
import ContentHubStoryListingLayout from "./ContentHubStoryListingLayout";
import ContentHubBlockB from "./Elements/ContentHubBlockB";
import ContentHubBlockF from "./Elements/ContentHubBlockF";
import ContentHubBlockI from "./Elements/ContentHubBlockI";
import ContentHubBodyContent from "./Elements/ContentHubBodyContent";
import ContentHubEditorsNote from "./Elements/ContentHubEditorsNote";
import ContentHubFeaturedSection from "./Elements/ContentHubFeaturedSection";
import ContentHubTitle from "./Elements/ContentHubTitle";

const { Heading } = Typography;

type Props = {
  data: Omit<ContentHubData, "metaData">;
};

const ContentHubLayoutElements = {
  ["contentBlockB"]: ContentHubBlockB,
  ["contentBlockF"]: ContentHubBlockF,
  ["contentBlockI"]: ContentHubBlockI,
  ["adsMidContent"]: () => (
    <div className={styles.gutterHorizontal}>
      <Ads.AdsMidContent />
    </div>
  ),
};

function ContentHubLayout({ data }: Props): ReactNode {
  //Config for content hub
  const {
    configMasthead,
    configBodyContent,
    configEditorsNote,
    configCallQoute,
    configDeals,
    configShareButton,
    configFeaturedSection,
    configTopStories,
  } = configContentHub;
  const ignoreIds: string[] = [];

  if (data.topStories) {
    data.topStories.forEach((item) => {
      if (item.id) {
        ignoreIds.push(item.id);
      }
    });
  }

  if (data.listedStories) {
    data.listedStories.forEach((item) => {
      if (item.id) {
        ignoreIds.push(item.id);
      }
    });
  }

  const coverCrop =
    data.coverImage?.crops.square_30_26 || data.coverImage?.crops.original;
  return (
    <Fragment>
      <div className={styles.fullWidthFeaturedImage}>
        {configMasthead.gradientOverlay?.display && (
          <div className={styles.gradientOverlay}></div>
        )}

        {configMasthead.heading.display && (
          <ContentHubTitle title={data.name} />
        )}

        {coverCrop && (
          <ResponsiveImage
            {...coverCrop}
            displayWidth={1000}
            mobileUp={
              data.coverImage?.crops.original && {
                ...data.coverImage.crops.original,
                displayWidth: 1920,
              }
            }
          />
        )}
      </div>

      <div
        className={cx(
          styles.bodyContainer,
          styles.gutter,
          styles.primaryContentContainer,
        )}
      >
        {configMasthead.heading.displayBelowFeaturedImage && (
          <Heading.SectionTitle nameZH={data.nameZH}>
            {data.name}
          </Heading.SectionTitle>
        )}

        {configBodyContent.display && (
          <ContentHubBodyContent
            data={{ name: data.name, storyPostData: data.storyPostData }}
          />
        )}

        {configEditorsNote.display && (
          <ContentHubEditorsNote editorsNote={data.editorsNote} />
        )}

        {configShareButton.display && (
          <div className={styles.pageShareOuterContainer}>
            <PageShare
              label="Share this article"
              url={`${configSite.address}${data.path}`}
              title={data.name}
              size={configSite.pageShare?.size}
              media={configSite.pageShare?.media}
              fill={configSite.pageShare?.fill}
            />
          </div>
        )}

        {(configBodyContent.display || configEditorsNote.display) && (
          <Ads.AdsMidContent />
        )}

        {configCallQoute.display && (
          <CallOnQuote
            description={
              data.storyPostData?.callOnQoute?.additionalFields.quotee?.value
            }
            title={
              data.storyPostData?.callOnQoute?.additionalFields.quote?.value
            }
          />
        )}

        {configFeaturedSection.display && (
          <ContentHubFeaturedSection featuredSection={data.featuredSection} />
        )}

        {((data.featuredSection && data.featuredSection.length > 0) ||
          data.storyPostData?.callOnQoute?.additionalFields.quote?.value) && (
          <Ads.AdsMidContent />
        )}
      </div>

      {data.socialVideo && data.socialVideo.videoInfo && (
        <div className={cx(styles.bodyContainer, styles.gutterBottomOnly)}>
          <div className={styles.videoGalleryPortraitContainer}>
            <PortraitScroll
              leftIcon={whiteArrowLeft}
              noOfItemsInView={3}
              rightIcon={whiteArrowRight}
              title={data.socialVideo.heading}
              items={data.socialVideo.videoInfo}
            />
          </div>
        </div>
      )}

      {configDeals.display && data.storyPostData?.contentListId && (
        <div
          className={cx(
            styles.bodyContainer,
            styles.gutter,
            styles.primaryContentContainer,
          )}
        >
          <Deals
            contentListId={data.storyPostData.contentListId}
            sectionHeading={configDeals.props?.heading}
            noOfItemsInFrame={configDeals.props?.noOfItemsInFrame}
            dimension={{ width: 190 }}
          />
        </div>
      )}

      {configTopStories?.display && (
        <div
          className={cx(
            styles.backgroundFill,
            styles.secondaryContentContainer,
            styles.gutterTopOnly,
          )}
        >
          {configContentHub.layout?.stories.elements.map((element, k) => {
            const ElementComponent = ContentHubLayoutElements[element.name];
            const code =
              configTrackingCode.contenthub?.topstories?.[element.name] ||
              GA4_REF_CODE_CONTENTBLOCK_L;

            return (
              <ElementComponent
                key={k}
                data={{
                  ...data,
                  ...(code && {
                    topStories: data.topStories?.map((teaser) =>
                      mapTeaserDataToLatestProps(teaser, code),
                    ),
                  }),
                }}
                firstItemAnchorBlock={
                  configContentHub.configTopStories?.firstItemAnchorBlock
                }
                from={element.from}
                limit={element.limit}
              />
            );
          })}

          {/* {listedStories story listing} */}
          <div
            data-layout-position="midcontenthub"
            className={cx(
              styles.gutterBottom,
              styles.secondaryContentContainer,
            )}
          >
            <ContentHubStoryListingLayout
              data={{
                listedStories: data.listedStories,
              }}
            />
            <NoSSR>
              <ContentHubStoryListing
                data={{
                  tagSlugs: data.tagSlugs.join(","),
                  limit: configTopStories.listingPerPage || 6,
                  ignoreIds: ignoreIds,
                  lb1: <Ads.AdsMidContent />,
                }}
              />
            </NoSSR>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ContentHubLayout;
