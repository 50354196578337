import { configHome, DataSource } from "@pub/config";
import { ContentBlock } from "@sphtech/dmg-design-system";
import ProductDeals from "@src/app/components/Modules/Product/ProductDeals";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
};

// Product Deals | Affiliate Products
export default function HomeAffiliateProduct({
  data,
  dataSource,
}: Props): ReactNode {
  const affiliateProduct = extractHomeContentData(data, dataSource);

  return (
    affiliateProduct &&
    affiliateProduct.items.length > 0 && (
      <div className={cx(styles.affiliateProductContainer)}>
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            heading={affiliateProduct.title || "editor’s picks"}
            withDivider={configHome.headingDivider}
            description={configHome.affiliateProduct?.description}
          >
            <ProductDeals data={{ items: affiliateProduct.items }} />
          </ContentBlock>
        </div>
      </div>
    )
  );
}
