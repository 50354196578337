import { configHome, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AffiliatesProductCarousell,
  ContentBlock,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
};

export default function HomeColumnSideScroll({
  data,
  dataSource,
}: Props): ReactNode {
  const columnSideScroll = extractHomeContentData(data, dataSource);

  return (
    columnSideScroll && (
      <div className={cx(styles.columnSideScrollContainer)}>
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            heading={columnSideScroll.title}
            cta={{
              url: columnSideScroll.url,
            }}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <AffiliatesProductCarousell
              itemHeight={300}
              itemWidth={190}
              items={columnSideScroll.items.map((item) => {
                const crop =
                  item.image?.crops.original || placeholders.portrait;
                return {
                  image: { ...crop },
                  link: item.path,
                  title: item.title,
                };
              })}
              noOfItemsInFrame={4}
            />
          </ContentBlock>
        </div>
      </div>
    )
  );
}
