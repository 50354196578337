import { RenderContext } from "@sphtech/web2-core/ssr";
import NeuronProvider from "@web2/providers/NeuronProvider";
import NewRelic from "@web2/providers/NewRelic";
import { ReactElement, StrictMode, useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { SWRConfig } from "swr";

import AnalyticsSettings from "./utils/AnalyticsSettings";
import HideMobileBanner from "./utils/HideMobileBanner";
import { fetcher } from "./utils/swr";

export default function App(): ReactElement {
  const renderContext = useContext(RenderContext);
  return (
    <StrictMode>
      <NeuronProvider>
        <ScrollRestoration />
        <HelmetProvider context={renderContext.helmet}>
          <NewRelic />
          <SWRConfig
            value={{
              fetcher,
            }}
          >
            <HideMobileBanner />
            <Outlet />
            <AnalyticsSettings />
          </SWRConfig>
        </HelmetProvider>
      </NeuronProvider>
    </StrictMode>
  );
}
