import { datetimeFormatter, loadScript } from "@sphtech/dmg-design-system";
import { isSponsoredStory } from "@src/app/utils/sponsored";
import AnalyticsGTM from "@src/app/utils/trackers/gtm";
import AnalyticsLotame from "@src/app/utils/trackers/lotame";
import AnalyticsPermutive from "@src/app/utils/trackers/permutive";
import AnalyticsSkimlinks from "@src/app/utils/trackers/skimlinks";
import AnalyticsSUID from "@src/app/utils/trackers/suid";
import { useEffect } from "react";

import gtmSrc from "../../js/gtm.js?url";
import { StoryData } from "../types/ServerCustomContext";

const VITE_DISABLE_ANALYTICS =
  import.meta.env.VITE_DISABLE_ANALYTICS === "true";

export default VITE_DISABLE_ANALYTICS ? () => null : AnalyticsSettings;

function AnalyticsSettings() {
  useEffect(() => {
    window.SPHMGTM.cmd.push("init_customevents");
    window.SPHMGTM.cmd.push("init_interactions");
    window.SPHMGTM.cmd.push("pageview");
    void loadScript(gtmSrc, false);
  }, []);
  return (
    <>
      <AnalyticsGTM />
      <AnalyticsLotame />
      <AnalyticsPermutive />
      <AnalyticsSUID />
      <AnalyticsSkimlinks />
    </>
  );
}

const AnalyticsArticle = VITE_DISABLE_ANALYTICS
  ? () => null
  : _AnalyticsArticle;

export { AnalyticsArticle };

type AnalyticsProps = {
  StoryData?: StoryData;
};

function _AnalyticsArticle({ StoryData }: AnalyticsProps) {
  const ArticleTypes: Record<string, string | undefined> = {
    OnlineStory: "Article",
    FeatureStory: "Featured article",
    GalleryStory: "Gallery article",
    VideoStory: "Video article",
    BrandedContent: "Branded Content",
  };

  const StoryDataSections = StoryData?.sections || [];
  const story_section = StoryDataSections[0] ? StoryDataSections[0].path : "";
  const [content_level_1, content_level_2, content_level_3] = story_section
    .split("/")
    .filter(Boolean);

  const analyticsData = {
    article_keyword:
      (StoryData?.tags && StoryData.tags.map((a) => a.name).join(",")) || "",
    article_type:
      (StoryData?.typeName && ArticleTypes[StoryData.typeName]) || "", //Article Featured article / Gallery article / Video article
    articleId: StoryData?.id,
    content_author:
      (StoryData?.authors && StoryData.authors.map((a) => a.name).join(";")) ||
      "",
    content_category: isSponsoredStory(
      StoryData?.sponsorship,
      StoryData?.typeName,
    )
      ? "ads"
      : "free",
    content_level_1,
    content_level_2,
    content_level_3,
    content_publication_date: StoryData?.publishDate
      ? datetimeFormatter(new Date(StoryData.publishDate), "yyyy-zpm-zpd")
      : "",
    content_publication_utc: StoryData?.publishDate || "",
    content_last_updated_utc: StoryData?.updateDate || "",
    content_type: "article",
    cue_articleid: StoryData?.id,
    locale: "en",
    page_name: StoryData?.processedElements.headline || "",
    //pagination: 1,
    //video_id: undefined,
    //video_title: undefined,
  };

  return (
    <script
      data-analytics-article={StoryData?.path}
      type="application/json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(analyticsData),
      }}
    />
  );
}
