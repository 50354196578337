import { CSSProperties } from "react";
//import our css
import styles from "./newsticker.module.css";

/**
 * This is newsticker component
 */
const NewsTicker: React.FC<{
  sourceFrom: string;
  items: {
    title: string;
    link: string;
  }[];
  /** ms per char */
  duration?: number;
}> = ({ sourceFrom, duration: duration = 100, items }) => {
  const charLength = items.reduce(
    (total, current) => total + current.title.length,
    0,
  );
  const totalDuration = duration * charLength;

  return (
    <div className={styles.newstickerContainer}>
      <div className={styles.newstickerLabel}>
        <b>{sourceFrom}</b>
      </div>
      <div
        className={styles.marquee}
        style={
          {
            "--custom-duration": `${Math.round(totalDuration)}ms`,
          } as CSSProperties
        }
      >
        <div className={styles.marqueeContent}>
          {items.map((item) => (
            <a key={item.link} href={item.link} target="_blank" rel="noopener">
              {item.title}
            </a>
          ))}
        </div>
        {/* content is duplicated to create the illusion of it looping indefinitely */}
        <div aria-hidden="true" className={styles.marqueeContent}>
          {items.map((item) => (
            <a key={item.link} href={item.link} target="_blank" rel="noopener">
              {item.title}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsTicker;
