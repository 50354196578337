import { ComponentProps, Fragment, ReactNode } from "react";
import cx from "classnames";

import * as Typography from "../../Element/Typography";
import Divider from "../../Element/Divider/divider";
import Button from "../../Element/Button";
import ResponsiveImage from "../../Element/ResponsiveImage";
import { SectionSubHeading } from "../../Block/SectionHeading/SectionHeading";
import { useCustomClassContext } from "../../../hooks/useCustomClassContext/CustomClassContext";
import NaturalCarousel from "../../Utility/NaturalCarousel/NaturalCarousel";

import styles from "./magazinelisting.module.css";

const { Heading } = Typography;
const { SubHeading3 } = Heading;

export type MagazineListingProps = {
  title: string;
  titleZH?: string;
  /**
   * Set this to true if you want to display a divider
   * between the carousel and subscription info
   */
  midDivider?: boolean;
  /**
   * This prop is use to configure the subscription info next to the carousel.
   * It expects an object with the following
   * attributes {subHeading: string, description: string;}
   */
  subscriptionInfo: {
    subHeading: string;
    description: string;
  };
  /**
   * This prop is use to configure the subscription CTA button. It expects an
   * object with the following attributes {
  url: "https://www.mynewsstand.online/product/harpers-bazaar-singapore",
  label: "Subscribe",
  variant: "refer to button element for more info",
  icon: "refer to button element for more info",
  iconPlacement: "refer to button element for more info";}
   */
  subscriptionCta: {
    url: string;
    label: string;
    variant?: "primary" | "secondary";
    icon?: ReactNode;
    iconPlacement?: "before" | "after";
  };
  carousel: {
    items: {
      image: string;
      srcWidth: number;
      srcHeight: number;
      alt: string;
      caption: string;
      url: string;
    }[];
    noItemsInFrame?: number;
  };
  headingDivider?: boolean;
  arrowSize?: number;
  arrowVariant?: ComponentProps<typeof NaturalCarousel>["arrowVariant"];
};

/**
 * This is our magazine listing component
 * @param MagazineListingProps
 * @returns
 */
function MagazineListing({
  title,
  titleZH,
  subscriptionInfo,
  subscriptionCta,
  midDivider = false,
  carousel,
  headingDivider,
  arrowSize,
  arrowVariant,
}: MagazineListingProps): ReactNode {
  const customClass = useCustomClassContext();

  return (
    <section className={styles.container}>
      <div className={cx(customClass?.magazineListing?.verticalLine)}>
        {titleZH ? (
          <Heading.SectionTitle nameZH={titleZH}>{title}</Heading.SectionTitle>
        ) : (
          <SectionSubHeading title={title} withDivider={headingDivider} />
        )}

        {/* Magazine issues display in carousel */}
        <div className={cx(styles.carouselContainer)}>
          <NaturalCarousel
            isLooping={false}
            showArrowsOnMobile={true}
            arrowSize={arrowSize || 52}
            arrowVariant={arrowVariant}
            centerItems
          >
            {carousel.items.map(
              (
                { caption, image, url, alt, srcWidth, srcHeight },
                index: number,
              ) => {
                return (
                  <a
                    key={index}
                    href={url}
                    target="_blank"
                    rel="noopener"
                    className={styles.card}
                    data-layout-position="magazinecard"
                    data-card-caption={caption}
                  >
                    <span className={styles.issueImage}>
                      <ResponsiveImage
                        src={image}
                        alt={alt}
                        srcWidth={srcWidth}
                        srcHeight={srcHeight}
                        displayWidth={275}
                        fill="contain"
                        fillAspectRatio={3 / 4}
                        loading="lazy"
                      />
                    </span>
                    <SubHeading3>{caption}</SubHeading3>
                  </a>
                );
              },
            )}
          </NaturalCarousel>
        </div>
      </div>

      <div
        className={cx(
          styles.subscriptionContainer,
          customClass?.magazineListing?.border,
        )}
      >
        {midDivider && (
          <div className={styles.midDivider}>
            <Divider />
            <span
              className={cx(
                styles.midDividerText,
                customClass?.magazineListing?.divider,
              )}
            >
              OR
            </span>
          </div>
        )}

        {/* Subscription related stuff */}
        <div className={styles.subscriptionInfoContainer}>
          {subscriptionInfo && (
            <Fragment>
              <SubHeading3 CustomElement="h3">
                {subscriptionInfo.subHeading}
              </SubHeading3>

              <p className={styles.description}>
                {subscriptionInfo.description}
              </p>
            </Fragment>
          )}

          {subscriptionCta && (
            <div className={styles.subscriptionBtn}>
              <Button
                href={subscriptionCta.url}
                target="_blank"
                variant={subscriptionCta.variant ?? "primary"}
                icon={subscriptionCta.icon}
                iconPlacement={subscriptionCta.iconPlacement ?? "after"}
                data-layout-position="magazinesubbutton"
              >
                {subscriptionCta.label}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default MagazineListing;
